import * as React from 'react'
import type { Props } from '@packages/common/src/components/TextInput/pure/Control'
import { IconName } from '@packages/common/src/components/Icon'
import FormValidationMessage from '@packages/common/src/components/FormValidationMessage'
import Adornment from '@packages/common/src/components/TextInput/pure/Adornment'
import FormControl from '@packages/common/src/components/TextInput/forms/FormControl'
import FormSingleLine from '@packages/common/src/components/TextInput/forms/FormSingleLine'
import View from '@packages/common/src/components/View'
import { FieldAttributes } from 'formik'

type FormProps = Omit<Props, 'withError' | 'onBlur' | 'children'> & {
  showValidationMessage?: boolean
  icon?: React.ReactNode | IconName
  type?: 'text' | 'email' | 'number' | 'password'
  step?: string | number
} & {
  autoComplete?: string
  validate?: FieldAttributes<any>['validate']
}

const FormTextInput = React.forwardRef<HTMLInputElement, FormProps>(
  (
    {
      name,
      showValidationMessage = true,
      icon,
      type,
      autoComplete,
      step,
      ...props
    },
    ref
  ) => (
    <View isVertical>
      <FormControl name={name} {...props}>
        <Adornment position="before">{icon}</Adornment>
        <FormSingleLine
          ref={ref}
          leftSpace={icon ? 24 : 0}
          type={type}
          autoComplete={autoComplete}
          step={step}
        />
      </FormControl>
      {showValidationMessage ? <FormValidationMessage name={name} /> : null}
    </View>
  )
)

export default FormTextInput
